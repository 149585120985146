.bg-icon {
  position: absolute;
  width: 110%;
  height: 100%;
}
.register-button-child {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.register-button-child {
  height: 100%;
  width: 100%;
  right: 0;
  bottom: 0;
  border-radius: 8px;
  background-color: #f7f8f9;
  border: 1px solid #dadada;
  box-sizing: border-box;
}
.enter-your-email {
  position:absolute;
  top: 0px;
  left: 0px;
  width: 485.5px;
  bottom: 0px;
  line-height: 125%;
  font-weight: 500;
  display:inline-block;
  align-items: flex-end;
}
.register-button {
  position: absolute;
  height: 7.78%;
  width: 25.78%;
  top: 30.83%;
  right: 37.11%;
  bottom: 61.39%;
  left: 37.11%;
}
.enter-your-password {
  position:absolute;
  top: 0px;
  left: 0px;
  width: 485.5px;
  bottom: 0px;
  line-height: 125%;
  font-weight: 500;
  display:inline-block;
  align-items: flex-end;
}
.fluenteye-20-filled-icon {
  position: absolute;
  top: 17px;
  left: 450.1px;
  border-radius: 8px;
  width: 21.93px;
  height: 22px;
  overflow: hidden;
}
.register-button1 {
  position: absolute;
  height: 7.78%;
  width: 25.78%;
  top: 40.69%;
  right: 37.11%;
  bottom: 51.53%;
  left: 37.11%;
}
.forgot-password {
  position: absolute;
  top: 354px;
  left: 694px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  text-align: right;
}
.login-button-child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 8px;
  background-color: #1e232c;
}
.login {
  position:absolute;
  top: 0px;
  left: 0px;
  width: 490.5px;
  bottom: 0px;
  line-height: 125%;
  font-weight: 500;
  display:inline-block;
  align-items: flex-end;
  color: #fff;
  background-color: #1e232c;
}
.inc-password {
  position: absolute;
  line-height: 125%;
  font-weight: 500;
  /* top: 10%; /* Adjust the top position as needed */
  /* left: 37.11%; */ 
  text-align: center;
  color: #e60404;
}
.login-button {
  position: absolute;
  height: 7.78%;
  width: 25.78%;
  top: 60.97%;
  right: 37.11%;
  bottom: 31.25%;
  left: 37.11%;
  text-align: center;
  color: #fff;
}
.dashboard-login {
  position: relative;
  background-color: #2148c0;
  width: 100%;
  height: 680px;
  overflow: hidden;
  text-align: left;
  font-size: 15px;
  color: #8391a1;
  font-family: Urbanist;
}
